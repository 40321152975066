import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { FcCheckmark } from "react-icons/fc";
import styled from "styled-components";

import { useAuth } from '../../context/auth-context';
import Accordion from '../../components/Accordion';
import { fetchData, createData } from '../../utils/fetchers';

function Assortment() {
    const [assortmentOptions, setAssortmentOptions] = useState(null);
    const [newOptionValue, setNewOptionValue] = useState("");
    const [newOptionParentId, setNewOptionParentId] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    let navigate = useNavigate();
    const { isAuthed, setIsAuthed } = useAuth();

    const getAssortmentOptions = useCallback(async () => {
        const res = await fetchData("/api/dsp/assortment");

        if (res.status === 401) {
            sessionStorage.removeItem('cId');
            setIsAuthed(false);
            return navigate("/login");
        } else {
            setAssortmentOptions(res.data);
        }
    }, [navigate, setIsAuthed])

    const createOption = async () => {
        if (!newOptionValue || newOptionValue === "")
            setError("Du måste ange ett namn")
        else if (!newOptionParentId || newOptionParentId === "")
            setError("Du måste välja en typ");
        else {
            try {
                const res = await createData("/api/dsp/assortment/types/options", {
                    assortmentTypeId: newOptionParentId,
                    name: newOptionValue,
                });
        
                if (res?.status === 200) {
                    getAssortmentOptions();
                    setNewOptionValue("");
                    setSuccess(res?.message);
                    setTimeout(() => {
                        setSuccess(null);
                    }, 3500)
                } else {
                    console.log(res?.validation?.hasErrors);
                    if (res?.validation?.hasErrors)
                        setError(res?.validation?.messages[0])
                    else
                        setError(res?.message);
                    setTimeout(() => {
                        setError(null);
                    }, 3500)
                }   
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleOnChange = (e) => {
        setNewOptionValue(e.target.value);
    }

    const handleSelectOnChange = (e) => {
        setNewOptionParentId(e.target.value);
    }

    useEffect(() => {
        let isMounted = true;

        if (!isAuthed) {
            navigate("/login");
        } else {
            if(isMounted) {
                getAssortmentOptions();           
            }
        }

        return () => isMounted = false;
    }, [navigate, isAuthed, getAssortmentOptions]);

    return (
        <div style={{ overflow: "scroll" }}>
            <h1>Sortimentsval</h1>
            {(assortmentOptions && Object.keys(assortmentOptions)?.length < 4) &&
                <>
                    <NewOptionContainer>
                        <div>
                            <select name="parentId" id="parentId" onChange={handleSelectOnChange}>
                                <option value="">Välj typ...</option>
                                <option value="aea5f4eb-c2fd-4b97-947b-a0fd28c2292f">Yrkesgrupper</option>
                                <option value="1de14fba-495f-4a77-9bd2-ede62aa98a0e">Typ av produkter</option>
                                <option value="1013dce9-1da3-4f15-a5b5-06f6f8241510">Varselkrav</option>
                                <option value="2761dcc7-975f-4c7b-af47-7ce321a39fa3">Färger</option>
                                
                                {/* DEV */}
                                {/* <option value="103d8b54-0882-45bd-9b17-f230993036a3">Yrkesgrupper</option>
                                <option value="0be2bd7b-9d6e-4236-bced-71a46f6cd964">Typ av produkter</option>
                                <option value="97fbed50-f4c3-47b3-bab0-44a89f380f9f">Varselkrav</option>
                                <option value="9f84618e-7d24-4026-b131-acf91477cd92">Färger</option> */}
                            </select>
                            <input 
                                type="text" 
                                value={newOptionValue} 
                                onChange={handleOnChange} 
                                placeholder="Namn"
                            />
                        </div>
                        <div>
                            <div onClick={createOption}><FcCheckmark /></div>
                        </div>
                    </NewOptionContainer>
                    {success && <p className="okay">{success}</p>}
                    {error && <p className="error">{error}</p>}
                </>
            }
            {assortmentOptions && Object.keys(assortmentOptions).length > 0 &&
                Object.keys(assortmentOptions).map(key =>
                    <Accordion 
                        key={key} 
                        heading={assortmentOptions[key][0].typeName} 
                        content={assortmentOptions[key]} 
                        parentId={assortmentOptions[key][0].assortmentTypeId} 
                        refetch={getAssortmentOptions}
                        url={"/api/dsp/assortment/types/options"}
                        type="assortment"
                    />
                )
            }
        </div>
    )
}

export default Assortment;

const NewOptionContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 0;

    > div:first-child {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

        select {
            margin-bottom: 10px;
            border: none;
            padding: 5px 10px;
        }

        input {
            padding: 5px 10px;
        }
    }

    > div:last-child {
        background-color: #EEEEEE;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 50%;     
        
        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                font-size: 18px;
            }
        }
    }
`